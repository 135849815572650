/* eslint-disable max-len */
import { Slot } from '@radix-ui/react-slot';
import { TooltipTrigger } from '@radix-ui/react-tooltip';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import Tooltip, { TooltipContent } from './tooltip';
import { cn } from './utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        primary: 'bg-brand text-white hover:opacity-90',
        danger: 'bg-red text-white hover:opacity-90',
        outline: 'border border bg-white text-dark-2 hover:bg-stroke',
        info: 'bg-teal text-white hover:opacity-90',
        warning: 'bg-orange text-white hover:opacity-90',
        link: 'text-brand underline-offset-4 hover:underline',
        icon: 'h-6 w-6 text-dark-2 border border-transparent bg-transparent hover:border-brand hover:border hover:text-brand'
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-6 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10'
      }
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default'
    }
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  tooltip?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, tooltip, asChild = false, ...props }, ref) => {
    let Comp;

    if (asChild) {
      Comp = Slot;
    } else if (tooltip) {
      Comp = TooltipTrigger;
    } else {
      Comp = 'button';
    }

    const buttonVariant = size === 'icon'
      ? 'icon'
      : variant;

    const buttonConf = { variant: buttonVariant, size, className };

    return tooltip
      ? (
        <Tooltip>
          <Comp
            className={cn(buttonVariants(buttonConf))}
            ref={ref}
            {...props}
          />
          <TooltipContent>{tooltip}</TooltipContent>
        </Tooltip>
      )
      : (
        <Comp
          className={cn(buttonVariants(buttonConf))}
          ref={ref}
          {...props}
        />
      );
  }
);
Button.displayName = 'Button';

export { Button as default, buttonVariants };
