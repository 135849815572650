import { useEffect, useRef, useState } from 'react';

import Button from './button';
import Dialog, { DialogContent, DialogFooter, DialogTitle } from './dialog';

const IdleReminder = ({ onStayLoggedIn, onLogout }: IdleDialogProps) => {
  const timerRef = useRef<Timer>();
  const [remainingTime, setRemainingTime] = useState(60 * 5);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setRemainingTime((prev) => {
        const nextTime = prev - 1;

        if (nextTime <= 0) {
          clearInterval(timerRef.current);
          onLogout();
        }

        return nextTime;
      });
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [onLogout]);

  return (
    <Dialog open>
      <DialogContent className='border border-brand max-w-[80vw] md:max-w-[420px]'>
        <DialogTitle>Reminder</DialogTitle>
        <p className='text-dark mb-6'>
          You have been inactive for a while. To secure your data, you will be
          logged out in{' '}
          <span className='font-bold text-xl text-brand'>{formatTime(remainingTime)}</span>
        </p>
        <DialogFooter className='flex justify-end'>
          <Button onClick={onLogout}
            variant='outline'>
            Logout
          </Button>
          <Button
            onClick={() => {
              onStayLoggedIn();
              clearTimeout(timerRef.current);
              setRemainingTime(60);
            }}
          >
            Stay Logged In
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default IdleReminder;

interface IdleDialogProps {
  onLogout: () => void;
  onStayLoggedIn: () => void;
}

function formatTime (seconds: number) {
  if (seconds >= 60) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes} minute${minutes !== 1
      ? 's'
      : ''} ${remainingSeconds} second${remainingSeconds !== 1
      ? 's'
      : ''}`;
  } else {
    return `${seconds} second${seconds !== 1
      ? 's'
      : ''}`;
  }
}
