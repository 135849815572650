import { BrowserCacheLocation, type Configuration } from '@azure/msal-browser';

import loadEnvVariables from './env';

const ENV_VARIABLES = loadEnvVariables();
const IS_IE =
  window.navigator.userAgent.includes('MSIE ') ||
  window.navigator.userAgent.includes('Trident/');

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: ENV_VARIABLES.policyName
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${ENV_VARIABLES.authority}/${ENV_VARIABLES.b2cTenlentId}/${ENV_VARIABLES.policyName}`
    }
  },
  authorityDomain: ENV_VARIABLES.authority
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ENV_VARIABLES.scopes
};

export const tokenRequest = {
  ...loginRequest
};

export const protectedResources = {
  upidApi: {
    endpoint: ENV_VARIABLES.apiUrl,
    scopes: {
      read: loginRequest.scopes,
      write: loginRequest.scopes
    }
  }
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: ENV_VARIABLES.azureClientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: ENV_VARIABLES.redirectUri,
    postLogoutRedirectUri: ENV_VARIABLES.postLogoutRedirectUri,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: IS_IE
  }
};
