import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import { Toaster } from 'sonner';

import { TooltipProvider } from 'shared/ui//tooltip';

import { msalConfig } from './config/auth';
import queryClient from './query-client';

const msalInstance = new PublicClientApplication(msalConfig);

export default function AppProviders ({ children }: Props) {
  return (
    <>
      <MsalProvider instance={msalInstance}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <TooltipProvider delayDuration={250}>{children}</TooltipProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </MsalProvider>
      <Toaster position='top-right'
        richColors />
    </>
  );
}

interface Props {
  children: React.ReactNode;
}
