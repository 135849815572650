import {
  type AccountInfo,
  type IPublicClientApplication
} from '@azure/msal-browser';
import axios, {
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig
} from 'axios';
import { jwtDecode } from 'jwt-decode';
// eslint-disable-next-line import/no-unresolved
import { toast } from 'sonner';

import loadEnvVariables from 'setup/config/env';

import SessionStorageUtils from './session-storage-utils';

const envConfig = loadEnvVariables();
const scopes = envConfig.scopes;

export default class HttpClient {
  static ACCESS_TOKNE_IDENTIFIER = 'HttpClientAccessToken';
  static accessToken = '';
  static account: AccountInfo;
  static instance: IPublicClientApplication;

  private readonly clientInstance!: AxiosInstance;

  constructor (baseURL: string) {
    this.clientInstance = axios.create({ baseURL });

    this.clientInstance.interceptors.response.use(
      (res) => res.data,
      async (error: AxiosError<BackendError>) => {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.error(error);
        } else {
          // eslint-disable-next-line no-console
          console.warn(error);
        }

        if (!error) {
          return Promise.reject(new Error('Unknown error occurred.'));
        }

        if (error.code === 'ECONNABORTED') {
          return Promise.reject(
            new HttpError('Request timed out.', HttpError.TIMEOUT)
          );
        }

        if (error.code === 'ERR_NETWORK') {
          const token = HttpClient.getAccessToken();

          if (HttpClient.isExpired(token)) {
            try {
              await HttpClient.acquireAndCacheAccessToken();
            } catch (e) {
              toast.error(
                'Your authentication status has expired. Please login again.'
              );
              window.location.reload();

              return;
            }
          }

          return Promise.reject(
            new HttpError('Network error occurred.', HttpError.NETWORK_ERROR)
          );
        }

        if (error.response && error.response.status >= 500) {
          return Promise.reject(
            new HttpError(
              'Service unavailable. Please try again later or contact support.',
              HttpError.SERVICE_UNAVAILABLE
            )
          );
        }

        if (error.response && error.response.status === 404) {
          return Promise.reject(
            new HttpError('Resource not found.', HttpError.RESOURCE_NOT_FOUND)
          );
        }

        const { message: axiosMessage, response } = error;
        const { data } = response ?? {};

        let errorMessage = axiosMessage;

        errorMessage =
          data?.message ??
          axiosMessage ??
          'Error occurred. Please try again later.';

        return Promise.reject(new Error(errorMessage));
      }
    );
  }

  static setAuthConfig = (
    instance: IPublicClientApplication,
    account: AccountInfo
  ) => {
    HttpClient.instance = instance;
    HttpClient.account = account;
  };

  static cacheAccessToken = (accessToken: string) => {
    SessionStorageUtils.setValue(
      HttpClient.ACCESS_TOKNE_IDENTIFIER,
      accessToken
    );
    HttpClient.accessToken = accessToken;
  };

  static acquireAndCacheAccessToken = async () => {
    const tokenConf = {
      scopes,
      account: HttpClient.account
    };

    let accessToken: string = '';

    try {
      const result = await HttpClient.instance.acquireTokenSilent(tokenConf);
      accessToken = result.accessToken;
    } catch (error) {
      const result = await HttpClient.instance.acquireTokenPopup(tokenConf);
      accessToken = result.accessToken;
    }

    HttpClient.cacheAccessToken(accessToken);

    if (accessToken) {
      return accessToken;
    }

    return Promise.reject(new Error('Failed to acquire access token.'));
  };

  static removeAccessToken = () => {
    HttpClient.accessToken = '';
    SessionStorageUtils.clearValue(HttpClient.ACCESS_TOKNE_IDENTIFIER);
  };

  static readonly getAccessToken = () => {
    const token =
      HttpClient.accessToken ??
      SessionStorageUtils.getValue<string>(HttpClient.ACCESS_TOKNE_IDENTIFIER);

    if (HttpClient.isExpired(token)) {
      HttpClient.removeAccessToken();

      return '';
    }

    return token;
  };

  private readonly getRequestConfig = async (conf?: AxiosRequestConfig) => {
    let accessToken = HttpClient.getAccessToken();

    if (!accessToken) {
      await HttpClient.acquireAndCacheAccessToken();
    }

    accessToken = HttpClient.getAccessToken();

    return {
      ...conf,
      headers: {
        ...conf?.headers,
        Authorization: `Bearer ${accessToken}`,
        'x-upid-api-key': '40eebc4d1ff847fc9d8a5da06ad9157b'
      }
    };
  };

  static readonly isExpired = (token: string) => {
    if (!token) {
      return true;
    }

    try {
      const { exp = -Infinity } = jwtDecode(token);

      return exp < Date.now() / 1000;
    } catch (e) {
      return true;
    }
  };

  delete = async (url: string, config?: AxiosRequestConfig) => {
    return this.clientInstance.delete(url, await this.getRequestConfig(config));
  };

  get = async <T>(url: string, params?: any) => {
    return this.clientInstance.get<T>(
      url,
      await this.getRequestConfig({ params })
    ) as Promise<T>;
  };

  patch = async <T>(url: string, data: any, conf?: AxiosRequestConfig) => {
    return this.clientInstance.patch<T>(
      url,
      data,
      await this.getRequestConfig(conf)
    ) as Promise<T>;
  };

  post = async <T>(url: string, data: any, conf?: AxiosRequestConfig) => {
    return this.clientInstance.post<T>(
      url,
      data,
      await this.getRequestConfig(conf)
    ) as Promise<T>;
  };

  put = async <T>(url: string, data: any, conf?: AxiosRequestConfig) => {
    return this.clientInstance.put<T>(
      url,
      data,
      await this.getRequestConfig(conf)
    ) as Promise<T>;
  };
}

interface BackendError {
  code: string;
  message: string;
}

export class HttpError extends Error {
  cause: string;

  static RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND';
  static TIMEOUT = 'TIMEOUT';
  static NETWORK_ERROR = 'NETWORK_ERROR';
  static SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE';

  constructor (message: string, cause: string) {
    super(message);
    this.cause = cause;
  }
}
