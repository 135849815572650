import { EventType, type IdTokenClaims } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal
} from '@azure/msal-react';
import { jwtDecode } from 'jwt-decode';
import { lazy, useCallback, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Navigate, Route, Routes } from 'react-router';

import IdleReminder from 'shared/ui/idle-reminder';
import HttpClient from 'shared/utils/http-client';

import { b2cPolicies, loginRequest } from './config/auth';

const CredentialsRoute = lazy(() => import('pages/credentials'));
const MarketplaceRoute = lazy(() => import('pages/marketplace'));
const SupportRoute = lazy(() => import('pages/support'));
const AppLayout = lazy(() => import('setup/app-layout'));

const DEFAULT_EXPIRES_IN = 55 * 60 * 1000;

function AuthApp () {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [idleTimeout, setIdleTimeout] = useState<number | undefined>(undefined);

  const [showIdleDialog, setShowIdleDialog] = useState(false);

  const { start, reset, activate } = useIdleTimer({
    startManually: true,
    timeout: idleTimeout,
    disabled: !idleTimeout,
    onIdle: () => {
      setShowIdleDialog(true);
    }
  });

  const acquireToken = useCallback(async () => {
    reset();

    let expireIn: number = -Infinity;

    try {
      const accessToken = await HttpClient.acquireAndCacheAccessToken();
      const exp = jwtDecode(accessToken)?.exp;
      expireIn = exp! * 1000 - Date.now() - 1000 * 60 * 5;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    } finally {
      setIdleTimeout(expireIn > 0
        ? expireIn
        : DEFAULT_EXPIRES_IN);

      activate();
      start();
    }
  }, [activate, start, reset]);

  const onLogout = useCallback(() => {
    instance.loginRedirect();
  }, [instance]);

  useEffect(() => {
    const callbackId = instance.addEventCallback(
      (event: { eventyType: EventType } & any) => {
        if (
          [
            EventType.LOGIN_SUCCESS,
            EventType.ACQUIRE_TOKEN_SUCCESS,
            EventType.SSO_SILENT_SUCCESS
          ].includes(event.eventType) &&
          event.payload
        ) {
          const { accessToken, account, idTokenClaims } = event.payload;

          // id token with policy
          const idToken = idTokenClaims as IdTokenClaims & {
            acr?: string;
            tfp?: string;
          };

          if (
            account &&
            [idToken?.acr, idToken?.tfp].includes(
              b2cPolicies.names.signUpSignIn
            )
          ) {
            instance.setActiveAccount(account);
            HttpClient.setAuthConfig(instance, account);
            HttpClient.cacheAccessToken(accessToken);
          }
        }

        if (
          [
            EventType.LOGIN_FAILURE,
            EventType.ACQUIRE_TOKEN_FAILURE,
            EventType.SSO_SILENT_FAILURE,
            EventType.LOGOUT_FAILURE
          ].includes(event.eventType)
        ) {
          HttpClient.removeAccessToken();
          instance.loginRedirect(loginRequest);
        }
      }
    );

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
        reset();
      }
    };
  }, [instance, inProgress, reset]);

  useEffect(() => {
    if (inProgress !== 'none') {
      return;
    }

    if (!isAuthenticated) {
      instance.loginRedirect(loginRequest);

      return;
    }

    const activeAccount = instance.getActiveAccount();
    const acounts = instance.getAllAccounts();

    if (!activeAccount && acounts.length) {
      instance.setActiveAccount(acounts[0]);
    }

    HttpClient.setAuthConfig(instance, activeAccount ?? acounts[0]);
    acquireToken();

    return () => {
      HttpClient.removeAccessToken();
    };
  }, [instance, inProgress, isAuthenticated, acquireToken]);

  return (
    <>
      <UnauthenticatedTemplate>
        <p aria-label='polite'
          className='sr-only'>
          Login Progress: {inProgress}
        </p>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        {showIdleDialog && (
          <IdleReminder
            onLogout={onLogout}
            onStayLoggedIn={() => {
              setShowIdleDialog(false);
              acquireToken();
            }}
          />
        )}
        <Routes>
          <Route element={<Navigate to='/app' />}
            index
            path='/' />
          <Route element={<AppLayout />}
            path='/app'>
            <Route element={<Navigate to='credentials' />}
              index />
            <Route element={<CredentialsRoute />}
              path='credentials/*' />
            <Route element={<MarketplaceRoute />}
              path='marketplace/*' />
            <Route element={<SupportRoute />}
              path='support' />
          </Route>
          <Route element={<Navigate to='/app/credentials' />}
            path='*' />
        </Routes>
      </AuthenticatedTemplate>
    </>
  );
}

export default AuthApp;
