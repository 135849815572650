import logInDev from 'shared/utils/logInDev';

const DEV_CONFIG = {
  apiUrl: '',
  authority: 'dev-auth.ultrapassid.com',
  azureClientId: 'fb6e5354-1503-4926-9526-4beffb7ccfbe',
  b2cTenlentId: 'bd3d4452-c569-4f00-8e01-10aeb00c8f47',
  policyName: 'B2C_1A_UAUTH_AUTHENTICATION',
  postLogoutRedirectUri: '/',
  redirectUri: '/',
  scopes: ['fb6e5354-1503-4926-9526-4beffb7ccfbe']
};

const PROD_CONFIG = {
  apiUrl: '',
  authority: 'auth.ultrapassid.com',
  azureClientId: 'fb6e5354-1503-4926-9526-4beffb7ccfbe',
  b2cTenlentId: 'bd3d4452-c569-4f00-8e01-10aeb00c8f47',
  policyName: 'B2C_1A_UAUTH_AUTHENTICATION',
  postLogoutRedirectUri: '/',
  redirectUri: '/',
  scopes: ['fb6e5354-1503-4926-9526-4beffb7ccfbe']
};

const SANDBOX_CONFIG = {
  apiUrl: '',
  authority: 'sandbox-auth.ultrapassid.com',
  azureClientId: 'fb6e5354-1503-4926-9526-4beffb7ccfbe',
  b2cTenlentId: 'bd3d4452-c569-4f00-8e01-10aeb00c8f47',
  policyName: 'B2C_1A_UAUTH_AUTHENTICATION',
  postLogoutRedirectUri: '/',
  redirectUri: '/',
  scopes: ['fb6e5354-1503-4926-9526-4beffb7ccfbe']
};

const LOCAL_CONFIG = DEV_CONFIG;

export default function loadEnvVariables () {
  const APP_ENV = process.env.REACT_APP_CUSTOM_ENVIRONMENT;

  switch (APP_ENV) {
  case 'development':
    return DEV_CONFIG;
  case 'local':
    return LOCAL_CONFIG;
  case 'staging':
    return SANDBOX_CONFIG;
  case 'production':
    return PROD_CONFIG;
  default:
    logInDev("Can't find corresponding environment");

    return LOCAL_CONFIG;
  }
}
