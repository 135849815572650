import loadEnvVariables from 'setup/config/env';

const envVariables = loadEnvVariables();

/* eslint-disable @typescript-eslint/no-extraneous-class */

const keyPrefix = `${process.env.NODE_ENV || envVariables.azureClientId}`;

export default class SessionStorageUtils {
  static setValue = (key: string, value: unknown) => {
    if (value === null || value === undefined) {
      SessionStorageUtils.clearValue(key);

      return;
    }

    const storedValue =
      typeof value === 'string'
        ? value
        : JSON.stringify(value);

    sessionStorage.setItem(`${keyPrefix}.${key}`, storedValue);
  };

  static getValue = <T = string>(key: string, fallback?: T) => {
    const storedValue =
      sessionStorage.getItem(`${keyPrefix}.${key}`) ?? JSON.stringify(fallback);

    try {
      return JSON.parse(storedValue) as T;
    } catch (e) {
      return fallback as T;
    }
  };

  static clearValue = (key: string) => {
    sessionStorage.removeItem(`${keyPrefix}.${key}`);
  };
}
